import * as Yup from 'yup'
import React, {useEffect, useRef, useState} from 'react'
import ImagesUtils from 'src/helpers/ImagesUtils'
import {
  api,
  useCreateItemMutation,
  useUpdateItemMutation,
} from '@cheddarup/api-client'
import type {FieldsEditValue} from '@cheddarup/core'
import * as WebUI from '@cheddarup/web-ui'
import {makeFieldGroup, unparseFieldGroups} from '@cheddarup/core'
import {useFormik, useLiveRef} from '@cheddarup/react-util'
import {getTicketHiddenFields} from '@cheddarup/core'
import * as Util from '@cheddarup/util'
import {
  deleteCollectionItemImage,
  uploadCollectionItemImage,
} from 'src/helpers/file-uploads'
import {readApiError} from 'src/helpers/error-formatting'
import {UpgradeRequiredAlert} from 'src/components/UpgradeRequiredAlert'
import {CalendarDateTime} from '@internationalized/date'
import {yupCalendarDateTimeSchema} from 'src/helpers/YupInternationalizedDateSchema'
import {useSaveFields} from 'src/hooks/fields'

import {TicketFormFields} from './TicketFormFields'
import {TicketFormDetails} from './TicketFormDetails'
import {TicketFormImagesAndDescription} from './TicketFormImagesAndDescription'
import {TicketFormAdvancedSettings} from './TicketFormAdvancedSettings'
import {getOrderedImages} from '../ItemFormPage/containers/FixedItemForm/FixedItemForm'

export interface TicketFormValues {
  parent_id: number | null
  name: string
  amount: string
  limit_per_person_quantity_enabled: boolean
  limit_per_person_quantity: string
  options: {
    makeAvailableQuantityPublic: boolean
    showAttendeeResponseOnConfirmationEmail: boolean
    time?: {
      startTime: CalendarDateTime | null
      endTime: CalendarDateTime | null
      timeZone: string
    }
    location?: {
      address: string
      city: string
      state: string
      country: string
      zip: string
    }
    waitlist: {
      enabled: boolean
      customMessage: string
    }
  }
  available_quantity_enabled: boolean
  available_quantity: string
  description: string
  required: boolean
  images: Array<null | {
    id: number
    contentType: string
    thumbnailCrop: Api.CropDetails | null
    image: Blob
  }>
}

export type TicketFormFormik = ReturnType<typeof useFormik<TicketFormValues>>

Yup.setLocale({mixed: {notType: 'Must be a number'}})

export interface TicketFormProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit' | 'onReset'> {
  collectionId: number
  itemId?: number | null
  onDismiss: () => void
  onDirtyChange?: (value: boolean) => void
}

export const TicketForm: React.FC<TicketFormProps> = ({
  collectionId,
  itemId,
  onDismiss,
  onDirtyChange,
  className,
  ...restProps
}) => {
  const itemQuery = api.tabItems.detail.useQuery(
    {
      pathParams: {
        tabId: collectionId,
        // biome-ignore lint/style/noNonNullAssertion:
        itemId: itemId!,
      },
    },
    {
      enabled: itemId != null,
    },
  )
  const fieldsQuery = api.fields.itemList.useQuery(
    {
      pathParams: {
        tabId: collectionId,
        // biome-ignore lint/style/noNonNullAssertion:
        itemId: itemId!,
      },
    },
    {
      enabled: itemId != null,
    },
  )
  const {data: collection, isFetching: isCollectionFetching} =
    api.tabs.detail.useQuery({
      pathParams: {
        tabId: collectionId,
      },
    })
  const createItemMutation = useCreateItemMutation()
  const updateItemMutation = useUpdateItemMutation()
  const saveFields = useSaveFields()
  const fieldsEditValueRef = useRef<FieldsEditValue[]>([])
  const upgradeRequiredAlertRef = useRef<WebUI.DialogInstance>(null)
  const upgradeRequireCheckPerformedRef = useRef(false)
  const tabsRef = useRef<WebUI.TabsInstance>(null)
  const growlActions = WebUI.useGrowlActions()
  const [selectedTabId, setSelectedTabId] = useState('details')
  const onDirtyChangeRef = useLiveRef(onDirtyChange)

  const item = itemQuery.data

  const fieldGroups = unparseFieldGroups({
    fieldGroups: [
      makeFieldGroup('full_name', {required: true}),
      makeFieldGroup('email', {required: true}),
    ],
    fields: [],
  })

  const hiddenFields = getTicketHiddenFields(
    fieldsQuery.data ?? fieldGroups.flatMap((fg) => fg.fields),
  )
  const hiddenFieldSets = (
    item?.options.fieldSets ?? fieldGroups.map((fg) => fg.fieldSet)
  ).filter((fs) =>
    hiddenFields.some((hf) => hf.metadata.fieldSetId === fs.uuid),
  )

  const formik = useFormik<TicketFormValues>({
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      amount: Yup.number()
        .transform((v) => (Number.isNaN(v) ? undefined : v))
        .max(999999, 'Price must be less than 1,000,000.00')
        .required('Required'),
      available_quantity_enabled: Yup.boolean(),
      available_quantity: Yup.number().when('available_quantity_enabled', {
        is: true,
        // biome-ignore lint/suspicious/noThenProperty:
        then: (schema) =>
          schema
            .transform((v) => (Number.isNaN(v) ? undefined : v))
            .required('Required'),
      }),
      limit_per_person_quantity_enabled: Yup.boolean(),
      limit_per_person_quantity: Yup.number().when(
        'limit_per_person_quantity_enabled',
        {
          is: true,
          // biome-ignore lint/suspicious/noThenProperty:
          then: (schema) =>
            schema
              .transform((v) => (Number.isNaN(v) ? undefined : v))
              .required('Required'),
        },
      ),
      options: Yup.object().shape({
        time: Yup.object().shape({
          startTime: yupCalendarDateTimeSchema().when(
            'endTime',
            ([endTime], schema) =>
              endTime ? schema.required('Required') : schema.nullable(),
          ),
          endTime: Yup.lazy(() =>
            yupCalendarDateTimeSchema().when(
              'startTime',
              ([startTime], schema) =>
                startTime
                  ? schema
                      .required('Required')
                      .min(startTime, `Can't be earlier than start time`)
                  : schema.nullable(),
            ),
          ),
          timeZone: Yup.string().when(
            ['startTime', 'endTime'],
            ([startTime, endTime], schema) =>
              startTime || endTime
                ? schema.required('Required')
                : schema.nullable(),
          ),
        }),
        waitlist: Yup.object().shape({
          enabled: Yup.boolean(),
          customMessage: Yup.string(),
        }),
      }),
    }),
    initialValues: {
      name: item?.name ?? '',
      parent_id: item?.parent_id ?? null,
      amount: item?.amount == null ? '' : String(item.amount.toFixed(2)),
      limit_per_person_quantity_enabled:
        item?.options?.perPersonMaxQuantity?.enabled ?? false,
      limit_per_person_quantity: item?.options?.perPersonMaxQuantity?.value
        ? String(item?.options?.perPersonMaxQuantity?.value)
        : '',
      options: {
        makeAvailableQuantityPublic:
          item?.options?.makeAvailableQuantityPublic ?? false,
        showAttendeeResponseOnConfirmationEmail:
          item?.options?.showAttendeeResponseOnConfirmationEmail ?? false,
        time: item?.options?.time
          ? {
              startTime: Util.parseCalendarDateTime(
                item.options.time.startTime,
                item.options.time.timeZone,
              ),
              endTime: Util.parseCalendarDateTime(
                item.options.time.endTime,
                item.options.time.timeZone,
              ),
              timeZone: item.options.time.timeZone,
            }
          : undefined,
        location: item?.options?.location,
        waitlist: {
          enabled: item?.options?.waitlist?.enabled ?? false,
          customMessage: item?.options?.waitlist?.customMessage ?? '',
        },
      },
      available_quantity_enabled:
        !itemId || item ? item?.available_quantity !== null : false,
      available_quantity: String(item?.available_quantity ?? ''),
      required: item?.required ?? false,
      description: item?.description ?? '',
      images: getOrderedImages(item?.images ?? []).map((itemImage) => ({
        // biome-ignore lint/style/noNonNullAssertion:
        id: itemImage.id!,
        contentType: itemImage.metadata.contentType ?? 'image/jpeg',
        thumbnailCrop:
          Object.keys(itemImage.metadata.thumbnail.cropDetails ?? {}).length > 0
            ? (itemImage.metadata.thumbnail.cropDetails as Api.CropDetails)
            : null,
        image: {
          ...itemImage,
          preview: ImagesUtils.getImageUrl(itemImage),
        } as any,
      })),
    },
    onSubmit: async (values) => {
      const payload = {
        name: values.name,
        required: values.required,
        parent_id: values.parent_id,
        images: values.images
          .filter((i) => i != null)
          .map((image, idx) => ({
            id: image.id,
            metadata: {
              contentType: image.contentType,
              thumbnail: {
                cropDetails: image.thumbnailCrop ?? {},
                order: idx,
              },
            },
          })),
        amount: Number(values.amount),
        amount_type: 'fixed' as const,
        available_quantity_enabled: values.available_quantity_enabled,
        allow_quantity: true,
        available_quantity: values.available_quantity_enabled
          ? Number(values.available_quantity)
          : null,
        description: values.description,
        options: {
          makeAvailableQuantityPublic:
            values.options.makeAvailableQuantityPublic,
          perPersonMaxQuantity: {
            enabled: values.limit_per_person_quantity_enabled,
            value: values.limit_per_person_quantity_enabled
              ? Number(values.limit_per_person_quantity)
              : 0,
          },
          fieldSets: [
            ...hiddenFieldSets,
            ...fieldsEditValueRef.current.map((fev) => fev.fieldSet),
          ],
          applicableForDiscount: true,
          itemSubType: 'ticket' as const,
          time: {
            ...values.options.time,
            startTime: values.options.time?.startTime
              ?.toDate(values.options.time.timeZone)
              .toISOString(),
            endTime: values.options.time?.endTime
              ?.toDate(values.options.time.timeZone)
              .toISOString(),
          },
          location: values.options.location,
          showAttendeeResponseOnConfirmationEmail:
            values.options.showAttendeeResponseOnConfirmationEmail,
          waitlist: values.options.waitlist,
        },
      }

      const localFields = [
        ...hiddenFields,
        ...fieldsEditValueRef.current.flatMap((fev) => fev.fields),
      ].map((f, idx) => ({
        ...f,
        position: idx,
      }))

      const someCheckboxOrMultipleChoiceFieldsEmpty = localFields
        .filter(
          (f) =>
            f.field_type === 'checkbox' || f.field_type === 'multiple_choice',
        )
        .some((f) => 'values' in f && f.values?.length === 0)
      if (someCheckboxOrMultipleChoiceFieldsEmpty) {
        growlActions.show('error', {
          title: 'Error',
          body: 'Checkbox and dropdown questions require at least one option',
        })
        tabsRef.current?.select('questions')
        return
      }
      const imagesToUpload = values.images
        .filter((image) => !!image?.id)
        .map((valueImage, idx) => ({...valueImage, order: idx}))

      let savedItem = item ?? null

      try {
        if (item) {
          const imagesToDelete = Util.differenceWith(
            item.images ?? [],
            values.images,
            (a, b) => a.id === b?.id,
          )
          const deleteImagePromises = imagesToDelete
            .filter((i): i is Api.S3Image & {id: number} => i.id != null)
            .map((image) =>
              deleteCollectionItemImage({
                id: image.id,
                collectionId,
                itemId: item.id,
              }),
            )
          const uploadImagePromises = imagesToUpload.map((image) =>
            uploadCollectionItemImage({
              itemId,
              imageId: image.id,
              tabId: collectionId,
              image: image.image,
              thumbnail: {
                order: image.order,
                cropDetails: image.thumbnailCrop ?? {},
              },
            }),
          )
          await Promise.all(deleteImagePromises)
          await Promise.all(uploadImagePromises)
          if (payload.available_quantity == null) {
            payload.available_quantity = null
          }
          savedItem = await updateItemMutation.mutateAsync({
            pathParams: {
              tabId: collectionId,
              itemId: item.id,
            },
            body: payload as any,
          })
        } else {
          savedItem = await createItemMutation.mutateAsync({
            pathParams: {
              tabId: collectionId,
            },
            body: payload as any,
          })

          const savedItemId = savedItem.id

          const uploadImagesPromises = imagesToUpload.map((image, idx) =>
            uploadCollectionItemImage({
              image: image.image,
              imageId: image.id,
              tabId: collectionId,
              itemId: savedItemId,
              thumbnail: {
                order: idx,
                cropDetails: image.thumbnailCrop ?? {},
              },
            }),
          )
          await Promise.all(uploadImagesPromises)
        }

        await saveFields({
          tabId: collectionId,
          tabObjectId: savedItem.id,
          tabObjectType: 'item',
          existingFields: fieldsQuery.data ?? [],
          newFields: localFields,
        })

        onDismiss()
      } catch (err) {
        const errMessage = readApiError(err, {
          recurring_contracts_active: () =>
            'This item has active recurring payers and cannot be updated. If you wish to make changes, please clone the item and hide this version.',
        })
        if (errMessage) {
          growlActions.clear()
          growlActions.show('error', {body: errMessage})
        }
      }
    },
  })

  useEffect(() => {
    if (
      !upgradeRequireCheckPerformedRef.current &&
      !itemId &&
      !isCollectionFetching
    ) {
      upgradeRequireCheckPerformedRef.current = true

      if (
        collection &&
        collection.status !== 'draft' &&
        collection.itemLimit != null &&
        !collection.is_pro &&
        collection.reportsAvailable.activeItemsCount >= collection.itemLimit
      ) {
        upgradeRequiredAlertRef.current?.show()
      }
    }
  }, [collection, isCollectionFetching, itemId])

  useEffect(() => {
    onDirtyChangeRef.current?.(formik.dirty)
  }, [formik.dirty])

  return (
    <>
      <form
        className={WebUI.cn('flex flex-col', className)}
        noValidate
        onSubmit={async (event) => {
          const errors = await formik.validateForm()

          if (Object.keys(errors).length > 0) {
            if (errors.limit_per_person_quantity) {
              tabsRef.current?.select('settings')
            } else if (errors.options?.time) {
              tabsRef.current?.select('description')
            } else if (Object.keys(errors).length > 0) {
              tabsRef.current?.select('details')
            }
          }

          formik.handleSubmit(event)
        }}
        onReset={formik.handleReset}
        {...restProps}
      >
        <WebUI.Tabs
          ref={tabsRef}
          className={`min-h-0 grow [&_>_.TabPanel:not([id="questions"])]:overflow-y-auto [&_>_.TabPanel:not([id="questions"])]:p-6 sm:[&_>_.TabPanel:not([id="questions"])]:px-9 sm:[&_>_.TabPanel:not([id="questions"])]:py-6 [&_>_.TabPanel[id="description"]]:py-0 [&_>_.TabPanel[id="questions"]]:overflow-y-hidden [&_>_.TabPanel]:grow`}
          variant="underlined"
          onChangeSelectedId={(newSelectedId) => {
            if (newSelectedId != null) {
              setSelectedTabId(newSelectedId)
            }
          }}
        >
          <WebUI.TabList
            aria-label="Ticket form navigation"
            className={
              'flex-0 border-b-0 sm:mx-13 [&_>_.TabList-underline]:bg-orange-50 [&_>_.Tab_>_.Button-content]:font-normal [&_>_.Tab_>_.Button-content]:text-ds-sm sm:[&_>_.Tab_>_.Button-content]:text-ds-md'
            }
          >
            <WebUI.Tab id="details">Details</WebUI.Tab>
            <WebUI.Tab id="description">Description</WebUI.Tab>
            <WebUI.Tab id="settings">Settings</WebUI.Tab>
            <WebUI.Tab id="questions">Questions</WebUI.Tab>
          </WebUI.TabList>

          <WebUI.Separator variant="primary" />

          <WebUI.TabPanel id="details">
            <TicketFormDetails formik={formik} collectionId={collectionId} />
          </WebUI.TabPanel>
          <WebUI.TabPanel id="description">
            <TicketFormImagesAndDescription
              collectionId={collectionId}
              formik={formik}
            />
          </WebUI.TabPanel>
          <WebUI.TabPanel id="settings">
            <TicketFormAdvancedSettings formik={formik} />
          </WebUI.TabPanel>
          <WebUI.TabPanel id="questions">
            {(!itemId || (itemQuery.isSuccess && fieldsQuery.isSuccess)) && (
              <TicketFormFields
                className="max-h-full"
                formik={formik}
                initialFieldSets={item?.options.fieldSets?.filter(
                  (fs) => !hiddenFieldSets.includes(fs),
                )}
                initialFields={fieldsQuery.data?.filter(
                  (f) => !hiddenFields.includes(f),
                )}
                onInit={(initialFieldsEditValue) => {
                  fieldsEditValueRef.current = initialFieldsEditValue
                }}
                onChange={(newFieldsEditValue) => {
                  fieldsEditValueRef.current = newFieldsEditValue

                  const localFieldSets = newFieldsEditValue.map(
                    (fev) => fev.fieldSet,
                  )
                  const localFields = newFieldsEditValue.flatMap(
                    (fev) => fev.fields,
                  )
                  onDirtyChange?.(
                    formik.dirty ||
                      !Util.deepEqual(
                        localFieldSets,
                        item?.options.fieldSets,
                      ) ||
                      !Util.deepEqual(localFields, fieldsQuery.data),
                  )
                }}
              />
            )}
          </WebUI.TabPanel>
        </WebUI.Tabs>

        <WebUI.Separator />
        <WebUI.HStack className="justify-end bg-natural-100 px-4 py-5">
          {!itemId && selectedTabId !== 'questions' ? (
            <WebUI.Button
              variant="default"
              size="large"
              onClick={() => tabsRef.current?.next()}
            >
              Continue
            </WebUI.Button>
          ) : (
            <WebUI.Button
              type="submit"
              variant="primary"
              size="large"
              loading={formik.isSubmitting}
            >
              Save
            </WebUI.Button>
          )}
        </WebUI.HStack>
      </form>
      <UpgradeRequiredAlert
        ref={upgradeRequiredAlertRef}
        onDidHide={() => onDismiss()}
      />
    </>
  )
}
