import {z} from '@cheddarup/util'

export const eSignatureSchema = z.object({
  signer_number: z.string(),
  signature_url: z.string().nullable(),
  initials_url: z.string().nullable(),
  first_name: z.string(),
  last_name: z.string(),
  ip: z.string(),
  device_info: z.string(),
  status: z.enum(['pending', 'completed']),
  created_at: z.string(),
  updated_at: z.string(),
})

declare global {
  namespace Api {
    // @ts-ignore
    type ESignature = z.infer<typeof eSignatureSchema>
  }
}
