import {
  keys,
  makeNonSecureShortId,
  makeUniqueIntGenerator,
  objectFromObject,
} from '@cheddarup/util'

export type FieldGroupType =
  | Exclude<Api.FieldSetType, 'general'>
  | Exclude<Api.TabObjectFieldType, 'image'>

export type FieldGroupTypeLabelless = Extract<
  FieldGroupType,
  'layout_description' | 'layout_divider'
>
export type FieldGroupTypeLabelled = Exclude<
  FieldGroupType,
  FieldGroupTypeLabelless
>

export type FieldGroupValue<TType extends FieldGroupType = FieldGroupType> =
  TType extends 'layout_description'
    ? string
    : TType extends 'multiple_choice' | 'checkbox'
      ? string[]
      : null

export interface FieldGroupToFieldLabelKeysMap
  extends Record<
    FieldGroupTypeLabelled,
    {[TKey in Api.TabObjectFieldIdentifier | 'value']?: string}
  > {
  address: {
    line1: string
    line2: string
    city: string
    state: string
    zip: string
  }
  full_name: {
    first_name: string
    last_name: string
  }
  signature: {
    legal_signature: string
    legal_initials: string
    value: string
  }
  file: {value: string}
  email: {value: string}
  phone: {value: string}
  text: {value: string}
  text_multiline: {value: string}
  multiple_choice: {value: string}
  checkbox: {value: string}
  date: {value: string}
  time: {value: string}
}

export interface FieldGroup<TType extends FieldGroupType = FieldGroupType> {
  uuid: string | null // if a `uuid` is null, that's a new field existing locally only
  id: string
  type: TType
  innerState: TType extends 'signature'
    ? {signType: 'signature' | 'initials' | 'signature_and_initials'}
    : undefined
  required: TType extends FieldGroupTypeLabelled ? boolean : undefined
  fieldLabels: TType extends FieldGroupTypeLabelled
    ? Record<keyof FieldGroupToFieldLabelKeysMap[TType], string>
    : undefined
  placeholders: TType extends FieldGroupTypeLabelled
    ? Record<keyof FieldGroupToFieldLabelKeysMap[TType], string>
    : undefined
  value: FieldGroupValue<TType>
}

export interface TransientField {
  transientId: number
  name: string
  field_type: Api.TabObjectFieldType
  required: boolean
  position: number
  values?: string | null
  metadata: {
    fieldSetId: string
    description?: {
      enabled: boolean
      value: string
    }
    fieldTypeMetadata: Api.TabObjectFieldTypeMetadata
  }
}

export interface FieldsEditValue {
  fieldSet: Api.FieldSet
  fields: Array<Api.TabObjectField | TransientField>
}

export function makeFieldGroup<TType extends FieldGroupType>(
  fieldGroupType: TType,
  overrides?: Partial<FieldGroup<TType>>,
): FieldGroup<TType> {
  switch (fieldGroupType) {
    case 'layout_description':
    case 'layout_divider': {
      return {
        uuid: null,
        id: makeNonSecureShortId(),
        type: fieldGroupType,
        value: fieldGroupType === 'layout_description' ? '' : null,
        ...overrides,
      } as any
    }

    default:
      return {
        uuid: null,
        id: makeNonSecureShortId(),
        type: fieldGroupType,
        required: false,
        fieldLabels: (defaultFieldGroupFieldLabels as any)[fieldGroupType],
        placeholders: (defaultFieldGroupFieldLabels as any)[fieldGroupType],
        value: ['multiple_choice', 'checkbox'].includes(fieldGroupType)
          ? []
          : null,
        innerState:
          fieldGroupType === 'signature' ? {signType: 'signature'} : undefined,
        ...overrides,
      } as any
  }
}

const defaultFieldGroupFieldLabels: {
  [TType in FieldGroupTypeLabelled]: Record<
    keyof FieldGroupToFieldLabelKeysMap[TType],
    string
  >
} = {
  full_name: {
    first_name: 'First Name',
    last_name: 'Last Name',
  },
  email: {
    value: 'Email Address',
  },
  phone: {
    value: 'Phone',
  },
  text: {
    value: 'Ask a question',
  },
  text_multiline: {
    value: 'Ask a question',
  },
  multiple_choice: {
    value: 'Ask a question',
  },
  checkbox: {
    value: 'Ask a question',
  },
  date: {
    value: 'Date',
  },
  time: {
    value: 'Time',
  },
  signature: {
    legal_signature: 'Signature',
    legal_initials: 'Initials',
    value: '',
  },
  file: {
    value: 'Upload File',
  },
  address: {
    line1: 'Street Address',
    line2: 'Street Address, Line 2',
    state: 'State/Province',
    city: 'City',
    zip: 'Zip/Postal Code',
  },
}

// MARK: – FieldSet and Field to FieldGroup transformations

export function parseFieldGroupType({
  fieldSet,
  fields,
}: {
  fieldSet: Api.FieldSet
  fields: Array<Api.TabObjectField | TransientField>
}): FieldGroupType | null {
  if (fieldSet.type !== 'general') {
    return fieldSet.type
  }
  if (fields.some((f) => f.field_type === 'signature')) {
    return 'signature'
  }

  const [anyField, secondField] = fields.filter(
    (f) => f.metadata.fieldSetId === fieldSet.uuid,
  )
  if (!anyField || !!secondField || anyField.field_type === 'image') {
    return null
  }

  return anyField.field_type
}

export function parseFieldGroup({
  fieldSet,
  fields,
}: {
  fieldSet: Api.FieldSet
  fields: Array<Api.TabObjectField | TransientField>
}): FieldGroup | null {
  const fieldSetFields = fields.filter(
    (f) => f.metadata.fieldSetId === fieldSet.uuid,
  )

  const hasSignatureField = fieldSetFields.some(
    (f) =>
      f.field_type === 'signature' &&
      f.metadata.fieldTypeMetadata?.fieldIdentifier === 'legal_signature',
  )
  const hasInitialsField = fieldSetFields.some(
    (f) =>
      f.field_type === 'signature' &&
      f.metadata.fieldTypeMetadata?.fieldIdentifier === 'legal_initials',
  )

  const common = {
    uuid: fieldSet.uuid,
    id: fieldSet.uuid,
    required: fieldSetFields.some((f) => f.required),
    innerState: undefined,
    value: null,
  }

  const fieldGroupType = parseFieldGroupType({fieldSet, fields})

  const fieldLabels =
    fieldSet.type === 'general'
      ? {}
      : objectFromObject(
          defaultFieldGroupFieldLabels[fieldSet.type],
          (fieldId, defaultLabel) => {
            const fieldLabel = fieldSetFields.find(
              (f) => f.metadata.fieldTypeMetadata?.fieldIdentifier === fieldId,
            )?.name
            return fieldLabel || defaultLabel
          },
        )

  switch (fieldSet.type) {
    case 'address':
    case 'full_name':
      return {
        ...common,
        type: fieldSet.type,
        fieldLabels,
        placeholders: defaultFieldGroupFieldLabels[fieldSet.type],
      }
    case 'general': {
      if (!fieldGroupType) {
        return null
      }

      return {
        ...common,
        innerState:
          fieldGroupType === 'signature'
            ? {
                signType:
                  hasSignatureField && hasInitialsField
                    ? 'signature_and_initials'
                    : hasSignatureField
                      ? 'signature'
                      : 'initials',
              }
            : undefined,
        type: fieldGroupType,
        fieldLabels:
          fieldGroupType === 'layout_description' ||
          fieldGroupType === 'layout_divider'
            ? {}
            : objectFromObject(
                defaultFieldGroupFieldLabels[fieldGroupType],
                (_fieldId, defaultLabel) =>
                  fieldSetFields[0]?.name ?? defaultLabel,
              ),
        value:
          // @ts-expect-error
          {
            layout_description: fieldSetFields[0]?.values ?? '',
            multiple_choice: fieldSetFields[0]?.values?.split('|||') ?? [],
            checkbox: fieldSetFields[0]?.values?.split('|||') ?? [],
          }[fieldGroupType] ?? null,
        placeholders:
          fieldGroupType === 'layout_description' ||
          fieldGroupType === 'layout_divider'
            ? undefined
            : defaultFieldGroupFieldLabels[fieldGroupType],
      }
    }
  }
}

export function parseFieldGroups({
  fields,
  fieldSets,
}: {
  fields: Array<Api.TabObjectField | TransientField>
  fieldSets: Api.FieldSet[]
}): FieldGroup[] {
  return fieldSets
    .map((fs) => parseFieldGroup({fieldSet: fs, fields}))
    .filter((fg) => fg != null)
}

export function normalizeFieldSetLessFields({
  fields,
}: {
  fields: Api.TabObjectField[]
}) {
  const fieldsWithoutFieldSet = fields
    .filter(
      (f) =>
        f.metadata.fieldSetId == null ||
        (f.metadata.description?.enabled && f.metadata.description.value),
    )
    .flatMap<Api.TabObjectField | TransientField>((f) => {
      if (f.metadata.description?.enabled && f.metadata.description.value) {
        return [
          {
            ...f,
            metadata: {
              ...f.metadata,
              description: {
                enabled: false,
                value: '',
              },
            },
          },
          {
            transientId: makeUniqueInt(),
            created_at: f.created_at,
            updated_at: f.updated_at,
            deleted_at: f.deleted_at,
            tab_object_id: f.tab_object_id,
            name: 'layout_description',
            required: false,
            position: f.position,
            field_type: 'layout_description' as const,
            values: f.metadata.description.value,
            metadata: {
              ...f.metadata,
              description: {
                enabled: false,
                value: '',
              },
            },
          } as TransientField,
        ]
      }

      return [f]
    })

  const transientFieldSets: Api.FieldSet[] = fieldsWithoutFieldSet.map(() => ({
    uuid: makeNonSecureShortId(),
    label: '',
    type: 'general',
  }))
  const transientFields = fieldsWithoutFieldSet.map(
    (f, idx) =>
      ({
        ...f,
        metadata: {
          ...f.metadata,

          fieldSetId: transientFieldSets[idx]?.uuid,
        },
      }) as Api.TabObjectField | TransientField,
  )

  return {
    fields: transientFields,
    fieldSets: transientFieldSets,
  }
}

// MARK: – FieldGroup to FieldSet and Field transformations

export function unparseFieldGroups({
  fieldGroups,
  fields,
}: {
  fieldGroups: FieldGroup[]
  fields: Array<Api.TabObjectField | TransientField>
}): FieldsEditValue[] {
  return fieldGroups.map((fg) => unparseFieldGroup({fieldGroup: fg, fields}))
}

export function unparseFieldGroup({
  fieldGroup,
  fields,
}: {
  fieldGroup: FieldGroup
  fields: Array<Api.TabObjectField | TransientField>
}): FieldsEditValue {
  switch (fieldGroup.type) {
    case 'address':
    case 'full_name':
      return {
        fieldSet: {
          uuid: fieldGroup.id,
          label: '',
          type: fieldGroup.type,
        },
        fields: unparseFields({fieldGroup, fields}),
      }
    default:
      return {
        fieldSet: {
          uuid: fieldGroup.id,
          label:
            fieldGroup.type === 'signature'
              ? (fieldGroup as FieldGroup<'signature'>).fieldLabels.value
              : '',
          type: 'general',
        },
        fields: unparseFields({fieldGroup, fields}),
      }
  }
}

export function unparseFields({
  fieldGroup,
  fields,
}: {
  fieldGroup: FieldGroup
  fields: Array<Api.TabObjectField | TransientField>
}): Array<Api.TabObjectField | TransientField> {
  const fieldSetFields = fields.filter(
    (f) => f.metadata.fieldSetId === fieldGroup.id,
  )

  switch (fieldGroup.type) {
    case 'address':
    case 'full_name':
      if (fieldSetFields.length > 0) {
        return fieldSetFields
          .map((f) => {
            const name = (fieldGroup.fieldLabels as any)[
              f.metadata.fieldTypeMetadata?.fieldIdentifier ?? ''
            ]
            return name
              ? {
                  ...f,
                  name,
                  required:
                    fieldGroup.required &&
                    f.metadata.fieldTypeMetadata?.fieldIdentifier !== 'line2',
                }
              : null
          })
          .filter((f): f is Api.TabObjectField => !!f)
      }

      // biome-ignore lint/style/noNonNullAssertion:
      return keys(fieldGroup.fieldLabels!).map((fieldIdentifier) => ({
        transientId: makeUniqueInt(),
        name: (fieldGroup.fieldLabels as any)[fieldIdentifier] as string,
        field_type: 'text',
        // biome-ignore lint/style/noNonNullAssertion:
        required: fieldIdentifier === 'line2' ? false : fieldGroup.required!,
        position: 0,
        values: null,
        metadata: {
          fieldSetId: fieldGroup.id,
          description: {enabled: false, value: ''},
          fieldTypeMetadata: {
            fieldIdentifier: fieldIdentifier as Api.TabObjectFieldIdentifier,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        },
      }))
    case 'signature':
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      return keys(fieldGroup.fieldLabels!)
        .filter((fieldIdentifier) => {
          if (fieldIdentifier === 'value') {
            return false
          }
          if (
            fieldGroup.innerState?.signType === 'signature' &&
            fieldIdentifier === 'legal_initials'
          ) {
            return false
          }
          if (
            fieldGroup.innerState?.signType === 'initials' &&
            fieldIdentifier === 'legal_signature'
          ) {
            return false
          }

          return true
        })
        .map((fieldIdentifier) => ({
          transientId: makeUniqueInt(),
          name: (fieldGroup.fieldLabels as any)[fieldIdentifier] as string,
          field_type: 'signature',
          required: fieldGroup.required ?? false,
          position: 0,
          metadata: {
            fieldSetId: fieldGroup.id,
            description: {enabled: false, value: ''},
            fieldTypeMetadata: {
              fieldIdentifier: fieldIdentifier as Api.TabObjectFieldIdentifier,
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
          },
        }))

    default: {
      const [existingField] = fieldSetFields
      if (existingField) {
        return [
          {
            ...existingField,
            name:
              fieldGroup.fieldLabels && 'value' in fieldGroup.fieldLabels
                ? fieldGroup.fieldLabels.value
                : existingField.name,
            required: fieldGroup.required ?? false,
            values: Array.isArray(fieldGroup.value)
              ? (fieldGroup.value.join('|||') ?? '')
              : (fieldGroup.value ?? ''),
          },
        ]
      }
      return [
        {
          transientId: makeUniqueInt(),
          name:
            fieldGroup.fieldLabels && 'value' in fieldGroup.fieldLabels
              ? fieldGroup.fieldLabels.value
              : fieldGroup.type,
          field_type: fieldGroup.type as any,
          required: fieldGroup.required ?? false,
          values: Array.isArray(fieldGroup.value)
            ? (fieldGroup.value.join('|||') ?? '')
            : (fieldGroup.value ?? ''),
          position: 0,
          metadata: {
            fieldSetId: fieldGroup.id,
            description: {enabled: false, value: ''},
            fieldTypeMetadata: {
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
          },
        },
      ]
    }
  }
}

const makeUniqueInt = makeUniqueIntGenerator()
