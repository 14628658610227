import {forwardRef} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {LinkButton} from 'src/components/LinkButton'

interface SignedFormNotEditableAlertProps extends WebUI.AlertProps {
  collectionId: number
  onPaymentsPage?: boolean
}

const SignedFormNotEditableAlert = forwardRef<
  WebUI.DialogInstance,
  SignedFormNotEditableAlertProps
>(({onPaymentsPage, collectionId, ...restProps}, forwardedRef) => (
  <WebUI.Alert
    aria-label="Form or waiver no longer editable"
    ref={forwardedRef}
    {...restProps}
  >
    <WebUI.AlertHeader>Form or waiver no longer editable</WebUI.AlertHeader>
    <WebUI.AlertContentView
      text={
        <WebUI.VStack className="gap-8">
          <span>
            This form or waiver is no longer editable as it is now legally
            binding with collected signatures. To make changes, please create a
            duplicate form or waiver (and consider hiding your existing
            version).
          </span>
          {onPaymentsPage && (
            <span>
              You can create a duplicate in the{' '}
              <LinkButton
                to={`/collection/${collectionId}/details/forms`}
                className="inline p-0 text-blue-500 underline"
              >
                Edit: Forms
              </LinkButton>{' '}
              section.
            </span>
          )}
        </WebUI.VStack>
      }
      actions={
        <>
          {onPaymentsPage && (
            <WebUI.AlertActionButton
              as={LinkButton}
              to={`/collection/${collectionId}/details/forms`}
              variant="primary"
            >
              Go to forms
            </WebUI.AlertActionButton>
          )}
          <WebUI.AlertCancelButton variant="secondary">
            Cancel
          </WebUI.AlertCancelButton>
        </>
      }
    />
  </WebUI.Alert>
))

export default SignedFormNotEditableAlert
