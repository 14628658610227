import * as Ariakit from '@ariakit/react'
import React from 'react'
import {cva} from 'class-variance-authority'

import {Loader} from '../Loader'
import {VariantsProps, cn} from '../../utils'
import {getStringFromChildren} from '@cheddarup/react-util'

export const nextButton = cva(
  [
    'relative inline-flex cursor-pointer select-none appearance-none flex-row items-center justify-center overflow-hidden outline-none transition-colors',
    'text-ellipsis whitespace-nowrap text-center font-body [&[type="submit"]]:font-extrabold',
    'aria-disabled:cursor-not-allowed',
  ],
  {
    variants: {
      variant: {
        teal: [
          'bg-teal-50',
          'font-normal text-natural-100',
          'hover:bg-teal-40',
          'data-[focus-visible=true]:ring-3 data-[focus-visible=true]:ring-teal-90',
          'aria-disabled:bg-teal-70',
        ],
        orange: [
          'bg-orange-50',
          'font-normal text-natural-100',
          'hover:bg-orange-40',
          'data-[focus-visible=true]:ring-3 data-[focus-visible=true]:ring-orange-70',
          'aria-disabled:bg-orange-70',
        ],
        outlined: [
          'border border-natural-70 bg-natural-100',
          'font-normal text-natural-10',
          'hover:shadow-30',
          'data-[focus-visible=true]:border-natural-50 data-[focus-visible=true]:shadow-30',
          'aria-disabled:border-natural-60 aria-disabled:text-natural-60',
        ],
        gray: [
          'font-normal text-natural-10',
          'hover:bg-natural-70',
          'data-[focus-visible=true]:ring-3 data-[focus-visible=true]:ring-natural-70 data-[focus-visible=true]:ring-inset',
          'aria-disabled:bg-natural-90 aria-disabled:text-natural-60',
        ],
        transparent: [
          'bg-transparent',
          'font-normal text-natural-30',
          'hover:bg-natural-90',
          'data-[focus-visible=true]:ring-3 data-[focus-visible=true]:ring-natural-70 data-[focus-visible=true]:ring-inset',
          'aria-disabled:text-natural-60',
        ],
        headless: '',
      },
      roundness: {
        none: 'rounded-none',
        default: 'rounded',
        pill: 'rounded-xl',
      },
      size: {
        xs: 'h-input-xs gap-2 px-3 py-2 text-ds-xs',
        sm: 'h-input-sm gap-2 px-4 py-2 text-ds-sm',
        md: 'h-input-md gap-2 px-5 py-2 text-ds-base',
        lg: 'h-input-lg gap-[10px] px-6 py-3 text-ds-md',
        xl: 'h-input-xl gap-3 px-7 py-3 text-ds-lg',
        headless: '',
      },
    },
    defaultVariants: {
      variant: 'teal',
      size: 'sm',
    },
  },
)

export interface NextButtonProps
  extends VariantsProps<typeof nextButton>,
    Ariakit.ButtonOptions,
    React.ComponentPropsWithoutRef<'button'> {
  loading?: boolean
}

export const NextButton = React.forwardRef<HTMLButtonElement, NextButtonProps>(
  (
    {
      variant = 'teal',
      roundness = 'default',
      size = 'sm',
      loading,
      render,
      className,
      disabled,
      children,
      ...restProps
    },
    forwardedRef,
  ) => {
    const isIconButton =
      getStringFromChildren(
        children ?? (React.isValidElement(render) ? render : undefined),
      ).length === 0

    return (
      <Ariakit.Button
        ref={forwardedRef}
        data-loading={loading}
        className={cn(
          nextButton({variant, size, roundness}),
          isIconButton &&
            {
              xs: 'px-[7px] py-2',
              sm: 'px-2 py-[10px]',
              md: 'px-[10px] py-[10px]',
              lg: 'px-[14px] py-3',
              xl: 'px-[18px] py-[18px]',
              headless: '',
            }[size],
          className,
        )}
        disabled={disabled || loading}
        render={render}
        {...restProps}
      >
        {loading && (
          <div className="absolute inset-0 flex flex-row items-center justify-center bg-natural-30/70">
            <Loader size="1.75em" variant="light" />
          </div>
        )}
        {children}
      </Ariakit.Button>
    )
  },
)

// MARK: AnchorButton

export interface NextAnchorButtonProps
  extends VariantsProps<typeof nextButton>,
    Ariakit.ButtonOptions,
    React.ComponentPropsWithoutRef<'a'> {}

export const NextAnchorButton = React.forwardRef<
  HTMLAnchorElement,
  NextAnchorButtonProps
>(
  (
    {
      variant,
      size,
      clickOnEnter,
      clickOnSpace,
      disabled,
      autoFocus,
      focusable,
      accessibleWhenDisabled,
      onFocusVisible,
      ...restProps
    },
    forwardedRef,
  ) => (
    <NextButton
      variant={variant}
      size={size}
      clickOnEnter={clickOnEnter}
      clickOnSpace={clickOnSpace}
      disabled={disabled}
      autoFocus={autoFocus}
      focusable={focusable}
      accessibleWhenDisabled={accessibleWhenDisabled}
      onFocusVisible={onFocusVisible}
      render={<Ariakit.Role.a ref={forwardedRef} {...restProps} />}
    />
  ),
)
