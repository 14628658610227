// @ts-ignore

import {z} from '@cheddarup/util'

import {tabMemberSchema} from './tab-members'
import {tabPaymentRefundableDataSchema} from './tab-payment-refunds'
import {paymentShipmentSchema, paymentShippingInfoSchema} from './shipments'
import {tabTaxSchema} from './tab-taxes'
import {paymentItemSchema} from './payment-items'
import {paymentStatusSchema} from './common'
import {
  paymentBalanceStatusSchema,
  paymentCheddarUpStatusSchema,
  paymentDisputeSchema,
  paymentMetadataExposedSchema,
  paymentMethodTypeSchema,
  recurringPaymentInvoiceSchema,
  scheduledInvoiceSchema,
} from './base-payments'
import {tabPaymentRefundSchema} from './refunds'
import {eSignatureSchema} from './e-signatures'

export const tabPaymentDiscountSchema = z.object({
  amount: z.number(),
  basis: z.number(),
  details: z.any(),
})

export const tabPaymentSchema = z.object({
  id: z.number(),
  created_at: z.string(),
  updated_at: z.string(),
  available_on: z.string(),
  balance_status: paymentBalanceStatusSchema.nullish(),
  cheddarup_status: paymentCheddarUpStatusSchema,
  e_signatures: eSignatureSchema.array(),
  member_fee: z.number(),
  note: z.string().nullable(),
  paid: z.boolean(),
  parent_payment_id: z.boolean().nullish(),
  payment_method: paymentMethodTypeSchema,
  status: paymentStatusSchema,
  stripe_charge_id: z.string().nullish(),
  stripe_transfer_id: z.string().nullish(),
  tab_id: z.number(),
  tab_member: tabMemberSchema,
  total: z.number(),
  user_fee: z.number(),
  uuid: z.string(),
  payment_items: paymentItemSchema.array(),
  discounts: z.record(tabPaymentDiscountSchema),
  total_discounts: z.number().nullish(),
  subtotal: z.number(),
  items_total: z.number(),
  total_taxes: z.number().nullish(),
  total_refund: z.number().nullish(),
  shipping_info: paymentShippingInfoSchema,
  taxes: z.record(tabTaxSchema.partial()),
  taxes_total: z.number(),
  can_refund: z.boolean().nullish(),
  fully_refunded: z.boolean(),
  refunds: tabPaymentRefundSchema.array().nullish(),
  scheduled_invoices: scheduledInvoiceSchema.array(),
  refundableData: tabPaymentRefundableDataSchema.nullish(),
  metadata_exposed: paymentMetadataExposedSchema.nullish(),
  recurring_payment_invoice: recurringPaymentInvoiceSchema.nullish(),
  shipping_charge: z.number().nullish(),
  dispute: paymentDisputeSchema.optional(),
  shipment: paymentShipmentSchema.nullish(),
  can_delete: z.boolean(),
})

declare global {
  namespace Api {
    // @ts-ignore
    type TabPayment = z.infer<typeof tabPaymentSchema>
  }
}
