import * as Ariakit from '@ariakit/react'
import React, {useContext, useMemo, useRef} from 'react'
import {useForkRef} from '@cheddarup/react-util'
import {cva} from 'class-variance-authority'

import {VariantsProps, cn} from '../../utils'
import {Merge} from '@cheddarup/util'

export interface InternalRadioGroupContextValue
  extends Pick<RadioProps, 'disabled' | 'size' | 'variant'> {
  name?: string
}

export const InternalRadioGroupContext = React.createContext(
  {} as InternalRadioGroupContextValue,
)

export interface RadioGroupProps
  extends Ariakit.RadioStoreProps,
    Pick<RadioProps, 'disabled' | 'size' | 'variant'>,
    Omit<Ariakit.RadioGroupProps, 'defaultValue' | 'store' | 'children'> {
  name?: string
  children?: React.ReactNode | ((radio: Ariakit.RadioStore) => React.ReactNode)
}

export const RadioGroup = React.forwardRef<HTMLDivElement, RadioGroupProps>(
  (
    {
      activeId,
      defaultActiveId,
      defaultItems,
      defaultValue,
      focusLoop,
      focusShift,
      focusWrap,
      includesBaseElement,
      items,
      orientation,
      rtl,
      setActiveId,
      setItems,
      setValue,
      store,
      value,
      virtualFocus,
      className,
      name,
      disabled,
      size,
      variant,
      children,
      ...restProps
    },
    forwardedRef,
  ) => {
    const radioStore = Ariakit.useRadioStore({
      activeId,
      defaultActiveId,
      defaultItems,
      defaultValue,
      focusLoop,
      focusShift,
      focusWrap,
      includesBaseElement,
      items,
      orientation,
      rtl,
      setActiveId,
      setItems,
      setValue,
      store,
      value,
      virtualFocus,
    })

    const contextValue = useMemo(
      () => ({
        name,
        disabled,
        size,
        variant,
      }),
      [name, disabled, size, variant],
    )

    return (
      <Ariakit.RadioProvider store={radioStore}>
        <InternalRadioGroupContext.Provider value={contextValue}>
          <Ariakit.RadioGroup
            ref={forwardedRef}
            className={cn('flex flex-col gap-3', className)}
            {...restProps}
          >
            {typeof children === 'function' ? children(radioStore) : children}
          </Ariakit.RadioGroup>
        </InternalRadioGroupContext.Provider>
      </Ariakit.RadioProvider>
    )
  },
)

export const radio = cva(
  'm-0 cursor-pointer appearance-none bg-natural-100 aria-disabled:cursor-not-allowed',
  {
    variants: {
      variant: {
        orange:
          'rounded-full aria-checked:bg-orange-40 aria-disabled:bg-orange-80',
        teal: 'rounded-full aria-checked:bg-teal-50 aria-disabled:bg-teal-75',
        headless: '',
      },
      size: {
        sm: 'h-[1.15em] w-[1.15em] text-ds-sm',
        md: 'h-[1.25em] w-[1.25em] text-ds-base',
        lg: 'h-[1.2em] w-[1.2em] text-ds-md',
        headless: '',
      },
    },
    defaultVariants: {
      variant: 'orange',
      size: 'md',
    },
  },
)

export interface RadioProps
  extends Merge<Ariakit.RadioProps, VariantsProps<typeof radio>> {}

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  ({size, variant, className, disabled, ...restProps}, forwardedRef) => {
    const ownRef = useRef<HTMLInputElement>(null)
    const ref = useForkRef(ownRef, forwardedRef)
    const contextValue = useContext(InternalRadioGroupContext)

    return (
      <Ariakit.Radio
        ref={ref}
        className={cn(
          radio({
            size: size ?? contextValue.size,
            variant: variant ?? contextValue.variant,
          }),
          className,
        )}
        name={contextValue.name}
        disabled={disabled ?? contextValue.disabled}
        {...restProps}
      />
    )
  },
)
