import queryString, {ParsedQuery} from 'query-string'

import {objectFromObject} from './object-utils'
import {getTokens, replaceTokens} from './string-utils'
import type {ExtractParams} from './type-utils'

// MARK: – Search params

type AnyParsedQuery = ParsedQuery<string | boolean | number>
type AnyParsedQueryKey = keyof AnyParsedQuery
type AnyParsedQueryValue = AnyParsedQuery[AnyParsedQueryKey]

export type Predicate =
  | AnyParsedQueryKey[]
  | ((key: AnyParsedQueryKey, value: AnyParsedQueryValue) => boolean)

// TODO: Cover with tests stage 1
export function pickSearchParams(search: string, predicate: Predicate) {
  const searchObj = queryString.parse(search)

  const filter = (key: AnyParsedQueryKey, value: AnyParsedQueryValue) =>
    typeof predicate === 'function'
      ? predicate(key, value)
        ? value
        : undefined
      : predicate.includes(key)
        ? value
        : undefined

  const filteredSearchObj = objectFromObject(searchObj, filter)

  return queryString.stringify(filteredSearchObj)
}

// TODO: Cover with tests stage 1
export function excludeSearchParams(search: string, predicate: Predicate) {
  return pickSearchParams(
    search,
    typeof predicate === 'function'
      ? (key, value) => !predicate(key, value)
      : (key) => !predicate.includes(key),
  )
}

// TODO: Cover with tests stage 1
export function mergeSearchParams(
  search: string,
  _newSearchParams:
    | Record<AnyParsedQueryKey, AnyParsedQueryValue | undefined>
    | string,
) {
  let newSearchParams = _newSearchParams
  if (typeof newSearchParams === 'string') {
    newSearchParams = queryString.parse(newSearchParams)
  }
  const searchObj = queryString.parse(search)

  return queryString.stringify({
    ...searchObj,
    ...newSearchParams,
  })
}

// MARK: – Path tokens

const pathParamRegex = /:[^/]+/g

export function replacePathTokens<T extends string>(
  path: T,
  pathParams: ExtractParams<T>,
) {
  return replaceTokens(path, pathParams, pathParamRegex, (match) =>
    match.slice(1),
  )
}

export function getPathTokens<T extends string>(path: T) {
  return getTokens(path, pathParamRegex)
}

// TODO: Cover with tests stage 1
export function normalizeUrl(url: string): string {
  let _url = url
  if (!url.match(/^[a-zA-Z]+:\/\//)) {
    _url = `https://${url}`
  }
  return _url
}

export function cleanUrl(str: string) {
  const url = new URL(str)
  return `${url.protocol}//${url.hostname}${url.port ? `:${url.port}` : ''}${url.pathname}`
}

// TODO: Cover with tests stage 1
/**
 * Configuration options for URL validation
 */
interface UrlValidationOptions {
  /** Whether to require protocol (http/https). Default is true. */
  requireProtocol?: boolean
  /** Whether to allow data URLs. Default is false. */
  allowDataUrl?: boolean
  /** Array of allowed protocols. Default is ['http:', 'https:']. */
  allowedProtocols?: string[]
}

/**
 * Checks if a given string is a valid URL.
 *
 * @param {string | null | undefined} string - The string to check.
 * @param {UrlValidationOptions} options - Optional configuration object.
 * @returns {boolean} - True if the string is a valid URL, false otherwise.
 *
 * @example
 * isUrl('https://www.example.com'); // true
 * isUrl('example.com', { requireProtocol: false }); // true
 * isUrl('not a url'); // false
 * isUrl('data:text/plain;base64,SGVsbG8sIFdvcmxkIQ==', { allowDataUrl: true }); // true
 */
export function isUrl(
  string: string | null | undefined,
  options: UrlValidationOptions = {},
): boolean {
  const {
    requireProtocol = true,
    allowDataUrl = false,
    allowedProtocols = ['http:', 'https:'],
  } = options

  if (!string || typeof string !== 'string') {
    return false
  }

  // Handle data URLs
  if (allowDataUrl && string.startsWith('data:')) {
    return /^data:([a-z]+\/[a-z0-9-+.]+)?;?(base64)?,([a-z0-9!$&',()*+;=\-._~:@/?%\s]*)?$/i.test(
      string,
    )
  }

  try {
    const url = new URL(string)

    if (!allowedProtocols.includes(url.protocol)) {
      if (requireProtocol || url.protocol !== 'http:') {
        return false
      }
    }

    return url.hostname.includes('.')
  } catch (e) {
    // If protocol is not required, try prepending 'http://' and check again
    if (!requireProtocol) {
      try {
        const urlWithProtocol = new URL(`http://${string}`)
        return urlWithProtocol.hostname.includes('.')
      } catch (e) {
        return false
      }
    }
    return false
  }
}

export function getFileNameByUrl(url: string) {
  try {
    const fileName = new URL(url).pathname.split('/').pop()
    if (!fileName) {
      return null
    }

    return decodeURIComponent(fileName)
  } catch {
    return null
  }
}
