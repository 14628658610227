import {useNavigate, useParams} from 'react-router-dom'
import {useFormik} from '@cheddarup/react-util'
import React, {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {
  PaymentType,
  useSelectedPaymentObject,
} from 'src/components/OrderSummaryLayout'
import * as Util from '@cheddarup/util'
import {FieldSetList} from 'src/components/FieldSetList'
import {useModifyFieldViewsMutation} from '@cheddarup/api-client'

export interface EditAttendeePageProps {
  paymentType?: PaymentType
}

const EditAttendeePage = ({paymentType = 'tab'}: EditAttendeePageProps) => {
  const navigate = useNavigate()
  const urlParams = useParams()
  const [paymentObject] = useSelectedPaymentObject(paymentType)
  const modifyFieldViewsMutation = useModifyFieldViewsMutation()
  const [notifyPayer, setNotifyPayer] = useState(false)

  const tabObject =
    paymentObject?.tab_item ??
    paymentObject?.tab_form ??
    paymentObject?.time_slot
  const fields =
    paymentObject?.tab_item?.fields ??
    paymentObject?.tab_form?.fields ??
    paymentObject?.time_slot?.spot.signup.fields ??
    []

  const formik = useFormik<Record<number, string>>({
    enableReinitialize: true,
    initialValues: Util.mapToObj(
      paymentObject?.item_field_views ?? [],
      (ifv) => [ifv.item_field_id, ifv.value ?? ''] as const,
    ),
    onSubmit: async (values) => {
      if (!paymentObject) {
        return
      }
      const fieldsPayload = Util.objectFromObject(
        values,
        (_fieldId, value) => ({value}),
        (fieldIdAsStr, value) => {
          const fieldId = Number(fieldIdAsStr)
          const correspondingFieldView = paymentObject?.item_field_views.find(
            (ifv) => ifv.item_field_id === fieldId,
          )
          const field = fields.find((f) => f.id === fieldId)
          const fieldType =
            correspondingFieldView?.field_type ?? field?.field_type
          if (
            !value ||
            fieldType === 'file' ||
            fieldType === 'signature' ||
            fieldType === 'image'
          ) {
            return undefined
          }

          if (
            !correspondingFieldView ||
            value === correspondingFieldView.value
          ) {
            return undefined
          }

          return String(correspondingFieldView.id)
        },
      )

      await modifyFieldViewsMutation.mutateAsync({
        pathParams: {
          // biome-ignore lint/style/noNonNullAssertion:
          tabId: urlParams.collection!,
          // biome-ignore lint/style/noNonNullAssertion:
          paymentId: urlParams.payment!,
        },
        body: {
          tabObjectId: tabObject?.id,
          paymentItemId: paymentObject.id,
          fields: fieldsPayload,
          notify: notifyPayer,
        },
      })
      navigate('..')
    },
  })

  return (
    <WebUI.Modal
      aria-label="Edit Attendee"
      className="sm:[&_>_.ModalContentView]:w-full sm:[&_>_.ModalContentView]:max-w-[548px]"
      preventBodyScroll
      onDidHide={() => navigate('..')}
    >
      <WebUI.ModalCloseButton />

      <WebUI.ModalHeader variant="compact">Attendee</WebUI.ModalHeader>
      <WebUI.Form
        className="px-9 pb-9 [&_>_.Form-inner]:gap-6"
        onSubmit={formik.handleSubmit}
        onReset={formik.handleReset}
      >
        {!!tabObject && (
          <FieldSetList
            key={JSON.stringify(formik.initialValues)}
            className="h-auto flex-0 gap-3 overflow-visible"
            EmptyStateViewComponent={React.Fragment}
            defaultValues={formik.initialValues}
            errors={formik.errors as Record<number, string>}
            fieldSets={
              'fieldSets' in tabObject.options
                ? (tabObject.options.fieldSets ?? [])
                : []
            }
            fields={fields}
            onValuesChange={(fieldId, value) =>
              formik.setFieldValue(String(fieldId), value)
            }
          />
        )}
        <WebUI.Disclosure visible={formik.dirty}>
          <WebUI.DisclosureContent>
            <WebUI.Checkbox
              name="notifyPayer"
              size="compact"
              checked={notifyPayer}
              onChange={(event) => setNotifyPayer(event.target.checked)}
            >
              Send automatic confirmation email to attendee
            </WebUI.Checkbox>
            <WebUI.HStack className="mt-6 gap-5">
              <WebUI.Button type="submit" loading={formik.isSubmitting}>
                Save
              </WebUI.Button>

              <WebUI.Button
                className="text-ds-sm"
                type="reset"
                variant="text"
                disabled={formik.isSubmitting}
                onClick={() => navigate(-1)}
              >
                Cancel
              </WebUI.Button>
            </WebUI.HStack>
          </WebUI.DisclosureContent>
        </WebUI.Disclosure>
      </WebUI.Form>
    </WebUI.Modal>
  )
}

export default EditAttendeePage
