import {z} from '@cheddarup/util'
import {eSignatureSchema} from '../schemas/e-signatures'
import {makeEndpoint} from '../utils'

const createBodySchema = z.object({
  first_name: z.string(),
  last_name: z.string(),
  signature_url: z.string().nullable(),
  initials_url: z.string().nullable(),
  device_info: z.string(),
})

export default {
  list: makeEndpoint({
    path: 'collections/:tabId/carts/:cartUuid/e_signatures',
    responseSchema: eSignatureSchema.array(),
  }),
  detail: makeEndpoint({
    path: 'collections/:tabId/carts/:cartUuid/e_signatures/:signerNumber',
    responseSchema: eSignatureSchema,
  }),
  create: makeEndpoint({
    path: 'collections/:tabId/carts/:cartUuid/e_signatures',
    method: 'POST',
    bodySchema: createBodySchema,
    responseSchema: eSignatureSchema,
  }),
  update: makeEndpoint({
    path: 'collections/:tabId/carts/:cartUuid/e_signatures/:signerNumber',
    method: 'PATCH',
    bodySchema: createBodySchema.partial(),
    responseSchema: eSignatureSchema,
  }),
}
