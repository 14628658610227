import {useLiveRef} from '@cheddarup/react-util'
import {useMemo, useSyncExternalStore} from 'react'

export function useScreenOrientationType<T = OrientationType>(
  selector?: (orientationType: OrientationType) => T,
) {
  const selectorRef = useLiveRef(selector)

  const orientationType = window.screen?.orientation
    ? useModernScreenOrientationType()
    : useLegacyScreenOrientationType()

  return useMemo(
    () => selectorRef.current?.(orientationType),
    [orientationType],
  )
}

const screenOrientationTypeStore = {
  subscribe: (callback: (orientationType: OrientationType) => void) => {
    function handleChange(event: Event) {
      const newOrientationType = (event.target as ScreenOrientation).type
      callback(newOrientationType)
    }

    screen.orientation.addEventListener('change', handleChange)

    return () => {
      screen.orientation.removeEventListener('change', handleChange)
    }
  },
  getSnapshot: () => screen.orientation.type,
}

function useModernScreenOrientationType() {
  return useSyncExternalStore(
    screenOrientationTypeStore.subscribe,
    screenOrientationTypeStore.getSnapshot,
  )
}

const legacyScreenOrientationTypeStore = {
  subscribe: (callback: (orientationType: OrientationType) => void) => {
    function handleOrientationChange() {
      const orientationType = getLegacyOrientationAngleAsOrientationType()
      callback(orientationType)
    }

    window.addEventListener('orientationchange', handleOrientationChange)

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange)
    }
  },
  getSnapshot: () => getLegacyOrientationAngleAsOrientationType(),
}

function useLegacyScreenOrientationType() {
  return useSyncExternalStore(
    legacyScreenOrientationTypeStore.subscribe,
    legacyScreenOrientationTypeStore.getSnapshot,
  )
}

// MARK: – Helpers

function getLegacyOrientationAngleAsOrientationType(): OrientationType {
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/orientation
  const angle = window.orientation as -90 | 0 | 90 | 180

  return (
    {
      '0': 'portrait-primary',
      '180': 'portrait-secondary',
      '-90': 'landscape-secondary',
      '90': 'landscape-primary',
    } as const
  )[angle]
}
