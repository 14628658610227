import {useDeleteCartFormMutation} from '@cheddarup/api-client'
import * as WebUI from '@cheddarup/web-ui'

export interface RemoveCartFormButtonProps
  extends WebUI.ButtonProps,
    Omit<React.ComponentPropsWithoutRef<'button'>, 'onClick'> {
  cartForm: Api.CheddarUpCartForm
  cartUuid: string
  collectionSlug: string
}

export const RemoveCartFormButton: React.FC<RemoveCartFormButtonProps> = ({
  cartForm,
  cartUuid,
  collectionSlug,
  size = 'compact',
  variant = 'outlined',
  className,
  children = 'Remove',
  ...restProps
}) => {
  const deleteCartFormMutation = useDeleteCartFormMutation()
  const isFormRequired =
    cartForm.tab_form.required || cartForm.detail.linked_item_id

  return (
    <WebUI.Button
      className={WebUI.cn('self-start', className)}
      size={size}
      variant={variant}
      loading={deleteCartFormMutation.isPending}
      disabled={isFormRequired}
      onClick={() =>
        deleteCartFormMutation.mutate({
          pathParams: {
            tabId: collectionSlug,
            cartUuid,
            formId: cartForm.id,
          },
        })
      }
      {...restProps}
    >
      {isFormRequired ? 'Required' : children}
    </WebUI.Button>
  )
}

export default RemoveCartFormButton
