import {sort} from '@cheddarup/util'

export function getTicketHiddenFields<
  T extends Pick<
    Api.TabObjectFieldView | Api.TabObjectField,
    'field_type' | 'metadata'
  >,
>(fieldViews: T[]) {
  return sort(
    fieldViews.filter(
      (field) =>
        field.field_type === 'email' ||
        field.metadata.fieldTypeMetadata?.fieldIdentifier === 'first_name' ||
        field.metadata.fieldTypeMetadata?.fieldIdentifier === 'last_name',
    ),
  ).asc((f) => ['first_name', 'last_name', 'email'].indexOf(f.field_type))
}

export function excludeTicketHiddenFields<
  T extends Pick<
    Api.TabObjectFieldView | Api.TabObjectField,
    'field_type' | 'metadata'
  >,
>(fieldViews: T[]) {
  return fieldViews.filter(
    (field) =>
      field.field_type !== 'email' &&
      field.metadata.fieldTypeMetadata?.fieldIdentifier !== 'first_name' &&
      field.metadata.fieldTypeMetadata?.fieldIdentifier !== 'last_name',
  )
}

export function getAttendeeName(
  fieldViews: Array<Pick<Api.TabObjectFieldView, 'value' | 'metadata'>>,
) {
  const firstName = fieldViews.find(
    (field) =>
      field.metadata.fieldTypeMetadata?.fieldIdentifier === 'first_name',
  )
  const lastName = fieldViews.find(
    (field) =>
      field.metadata.fieldTypeMetadata?.fieldIdentifier === 'last_name',
  )

  return `${firstName?.value ?? ''} ${lastName?.value ?? ''}`
}

export function getAttendeeEmail(
  fieldViews?: Array<Pick<Api.TabObjectFieldView, 'field_type' | 'value'>>,
) {
  const email = fieldViews?.find((field) => field.field_type === 'email')
  return email?.value ?? ''
}

export function resolveSignatureFieldValue(
  fieldView: Pick<Api.TabObjectFieldView, 'field_type' | 'metadata' | 'value'>,
  eSignatures: Api.ESignature[],
) {
  if (fieldView.field_type === 'signature') {
    const eSignature = eSignatures.find(
      (es) => es.signer_number === fieldView.value,
    )
    const fieldIdentifier =
      fieldView.metadata.fieldTypeMetadata?.fieldIdentifier

    if (eSignature) {
      if (fieldIdentifier === 'legal_initials') {
        return eSignature.initials_url
      }
      if (fieldIdentifier === 'legal_signature') {
        return eSignature.signature_url
      }
    }
  }

  return fieldView.value
}
